import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/01-new/molecules/03-decorations/FadeAnimationContainer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/01-new/organisms/00-ui/Cursor/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/01-new/organisms/01-sections/AuditPopupActionContainer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/01-new/organisms/01-sections/FeedbacksSwiperSection/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/01-new/organisms/01-sections/FormPopupActionContainer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/01-new/organisms/01-sections/JourneySection/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Banners/CollapsableBanner/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Banners/IndustriesBanner/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Common/Cases/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Common/Services/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Layouts/Intros/VideoBackgroundIntro/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Layouts/Section/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/MainIntro/index.scss");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/NavBar/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Swipers/ClientsSwiper/index.tsx")