'use client';

import { ContactFormPopup } from '@/components/Common/ContactFormPopup';
import { PropsWithChildren, useState } from 'react';
import { MarkedListItem } from '@/molecules/00-ui/MarkedListItem';
import parse from 'html-react-parser';

export const AuditPopupActionContainer = ({ children }: PropsWithChildren) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <ContactFormPopup
        title={parse('Get <span>strategic</span> before you start building')}
        subtitle="Every successful custom software project starts with clarity"
        descriptionSlot={
          <ul className="flex flex-col gap-y-base mb-[40px]">
            <li>
              <MarkedListItem
                text="Our <span>free strategic session</span> with a Syberry Solution Architect helps you validate
                your vision, avoid costly missteps, and uncover the most efficient path forward —
                before you invest in development."
                themeColor="green"
              />
            </li>
            <li>
              <MarkedListItem
                text="Whether you’re refining an idea or already deep into planning, this conversation
                will bring immediate technical insight and real-world guidance to your project."
              />
            </li>
          </ul>
        }
        show={isOpen}
        onClose={() => setOpen(false)}
        isAudit
      />
      <div onClick={() => setOpen(true)}>{children}</div>
    </>
  );
};
