import { cn } from '@/helpers/tailwind';
import { Typography } from '@/atoms/00-ui/Typography';
import { IMarkedListItemProps } from '@/molecules/00-ui/MarkedListItem/index.types';
import parse from 'html-react-parser';

export const MarkedListItem = ({ text, themeColor = 'pink' }: IMarkedListItemProps) => {
  return (
    <div className="flex gap-x-base items-center">
      <div
        className={cn(
          `w-[13px] mobile:w-[15px] h-[19.5px] self-start mt-[2px]
          bg-center bg-no-repeat bg-contain
          shrink-0 will-change-transform`,
          {
            ['bg-[url(/icons/arrows/arrow-pink.svg)]']: themeColor === 'pink',
            ['bg-[url(/icons/arrows/arrow-green.svg)]']: themeColor === 'green',
          },
        )}
      />
      <Typography
        component="span"
        variant="body2"
        className={cn({
          ['[&>span]:text-purple-7']: themeColor === 'pink',
          ['[&>span]:text-green-7']: themeColor === 'green',
        })}
      >
        {parse(text)}
      </Typography>
    </div>
  );
};
